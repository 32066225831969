/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.react-bootstrap-table-pagination-list .pagination {
    justify-content: flex-end;
}

.search-label {
    display: block !important;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.badge-success {
    color: #333333cf;
    background-color: #dff0d8;
}

.badge-warning {
    color: #333333cf;
    background-color: #fcf8e3;
}

.vr-container {
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    border-radius: 4px;
}

    .vr-container .title {
        display: flex;
        color: #222;
        background: #f5f5f5;
        border-radius: 4px 4px 0px 0px;
        padding: 5px;
        border-bottom: 1px solid #ddd;
    }

    .vr-container form {
        padding: 10px;
    }

    .vr-container .vr-formgroup {
        display: flex;
        align-items: center;
    }

        .vr-container .vr-formgroup input, .vr-container .vr-formgroup .fakeinput, .vr-container .vr-formgroup .custom-select {
            width: 80%;
            margin-left: 20px !important;
        }

        .vr-container .vr-formgroup input[type="checkbox"] {
            width: 80%;
            margin-left: 0px !important;
            width: 20px;
            height: 20px;
        }


    .vr-container .vr-formgroup .form-label {
        min-width: 100px;
    }

    .event-stat-row {
        display: flex;
    }

        .event-stat-row .event-stat {
            display: flex;
            margin-right: 20px;
            justify-content: space-between;
        }

    .event-stat label {
        font-weight: 700;
        min-width: 150px;
        margin-right: 20px;

    }

    .event-stat div {
    }


.eventplan {

}

.eventplan .group {
    padding: 5px;
    background: white;
    text-align: left;
    margin-bottom: 8px;
    width: 100%;
}

.eventplan .group .group-title {
    color: #222;
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    cursor: pointer;
}

.eventplan .group .group-title:hover {
    background: #e6e6e6;
    transition: all 0.3s ease-in-out;
}


.eventplan .group h1 {
    font-size: 20px;
}

.eventplan .group h2 {
    font-size:  14px;
}

.eventplan .group .fa-check {
    color: green;
}

.eventplan .session {
    text-align: left;
    font-size: 14px;
    margin-left: 10px;
    padding: 10px;
}

.eventplan .session.complete {
    color: green;
}

.eventplan input[type='checkbox'] {
    transform: scale(1.5);
    filter: hue-rotate(255deg);
    cursor: pointer;
}

.vrdisabled {
    pointer-events: none;
}